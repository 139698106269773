import type { ApiResponse } from '~/types'

export const useGetDashboardTimezones = async () => {
  const { data, error } = await useAPIFetch(`/dashboard/timezones`)
  if (error.value) throw createError(error.value)
  return data.value
}
export const useUpdateDashboardTimezones = async (timezones: []) => {
  const { data, error } = await useAPIFetch(`/dashboard/timezones`, {
    method: 'POST',
    body: { timezone_ids: timezones },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetDashboardRequiredActions = async () => {
  const { data, error } = await useAPIFetch(`/dashboard/required-actions`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetDashboardStatistics = async (period: string) => {
  const { data, error } = await useAPIFetch(`/dashboard/statistics?period=${period}`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetDashboardProperties = async (params: Record<string, any>) => {
  return await useAPIPropertiesFetch<ApiResponse<any> & { total: number }>(`residentials/dashboard`, {
    params,
  })
}

export const useGetDashboardCommission = async (params?: Record<string, any>) => {
  const { data, error } = await useAPIFetch(`/dashboard/commission`, {
    params,
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetDashboardContacts = async () => {
  const { data, error } = await useAPIFetch(`/dashboard/contacts`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetDashboardCommissionPrograms = async () => {
  const { data, error } = await useAPIFetch(`/dashboard/commission-programs`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const applyCommissionProgram = async (body: any) => {
  const { data, error } = await useAPIFetch(`/dashboard/commission-programs/apply`, {
    method: 'POST',
    body,
  })
  if (error.value) throw createError(error.value)
  return data.value
}
